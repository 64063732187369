import React from 'react';
import '../styles/settings.css';

class Settings extends React.Component {
	

	constructor(props){
		super(props);

	}

	
	render() {
		return (
		
		<div className="Settings">
            <h>Hi Porcupines</h>
		</div>
		);
	}
} 

export default Settings